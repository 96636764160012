<template>
    <div class="main-wrapper">
        <div class="admin-top">
            <div class="top-left">
                <span class="left-title">分类管理</span>
            </div>
            <div class="top-right">
                <el-button type="primary" @click="addClassifies()">添加分类</el-button>
            </div>
        </div>
        <el-table :data="clasList" class="college-table" style="width: 100%; flex: 1" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column type="expand" align="center">
                <template slot-scope="props">
                    <el-form inline class="demo-table-expand" v-for="item in props.row.subClassification" v-bind:key="item.id">
                    <span class="erjiname">{{item.name}}</span>
                    <el-button class="ejbtn1" style="color:#000000" type="text" size="small" @click="editCollege(item)">编辑</el-button>
                    <el-button class="ejbtn2" type="text" size="small" @click="deleteCollege(item)">删除</el-button>
                    </el-form>
                </template>
            </el-table-column>
           
            <el-table-column prop="name" label="名称" align="center">
                <template slot-scope="scope">
                    <span class="school-name">{{scope.row.name}}</span>
                </template>
                
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="addsubclass(scope.row)">添加子分类</el-button>
                    <el-button style="color:#000000" type="text" size="small" @click="editCollege(scope.row)">编辑</el-button>
                    <el-button type="text" size="small" @click="deleteCollege(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                v-if="clasList.length>0"
                style="margin-bottom: 10px"
                class="pages-center"
                :current-page="adminPages.currentPageNum"
                :page-size="adminPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="adminPages.total"
                @current-change="adminCurrentChange"
        ></el-pagination>
    </div>
</template>

<script>
    export default {
        name: "ClassifiesManage",
        data(){
            return {
                clasList:[],
                cijiList:[],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        created(){
            this.getClasList();
        },
        methods:{
            //获取分类
            getClasList(index){
                let params = {
                    page: this.adminPages.currentPageNum,
                    paging:1,
                    pageSize:this.adminPages.eachPageNum
                };
                this.$http.axiosGetBy(this.$api.classification,params,(res)=>{
                    console.log(88,res)
                    if(res.code == 200){
                        this.clasList = res.data.data;
                        this.adminPages.total = res.data.total;
                    }
                })
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getClasList()
            },
            //添加分类
            addClassifies(){
                let that = this;
                that.$prompt('分类名称','添加分类',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value })=>{
                    if (value === null || value === '') {
                        this.$message.error('添加失败')
                    } else {
                        let formData = new FormData();
                        formData.append('name',value);
                        that.$http.axiosPost(that.$api.classification,formData,(res)=>{
                            if (res.code === 200) {
                                that.$message({
                                    type: 'success',
                                    message:'添加'+ value + '成功'
                                })
                                that.getClasList();
                            } else {
                                that.$message({
                                    type: 'error',
                                    message: res.message
                                })
                            }
                        });
                    }
                }).catch(()=>{
                    that.$message({
                        type:'info',
                        message:'取消添加'
                    })
                })
            },
            //编辑分类
            editCollege(row){
                let that = this;
                console.log(row);
                const name = row.name; 
                const id = row.id;
                that.$prompt('分类名称','编辑分类',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPlaceholder:name
                }).then(({value})=>{
                    if (value === null || value === '') {
                        this.$message.error('修改失败')
                    } else {
                        let formData = new FormData();
                        formData.append('id',id);
                        formData.append('name',value);
                        that.$http.axiosPost(that.$api.classification,formData,(res)=>{
                            if(res.code == 200){
                                this.$message.success(res.message);
                                that.getClasList();
                            }else{
                                this.$message.warning(res.message)
                            }
                        },(err)=>{
                            console.log(err)
                        });
                    }

                }).catch(()=>{
                    that.$message({
                        type:'info',
                        message:'取消编辑'
                    })
                })
            },
            //删除分类
            deleteCollege(row){
                let that = this;
                console.log(11,row)
                const id = row.id;
                that.$confirm('删除系统任务后不能恢复，确定要删除吗？','删除任务',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    console.log(12,row.id)
                    that.$http.axiosPost(that.$api.classificationdelete,{id:row.id},(res)=>{
                        console.log(10,res)
                        if(res.code == 200){
                            console.log(111,that.clasList)
                            that.$message({
                            type: 'success',
                            message:'删除成功'
                    })
                    that.getClasList();
                        }else{
                            that.$alert('该分类下存在资源无法删除，先删除资源','删除分类',{
                                confirmButtonText: '确定',
                                // center:true,
                                confirmButtonClass:'failbtn',
                                callback:action =>{
                                    that.$message({
                                        type:'info',
                                        message: '删除失败'
                                    })
                                }
                            })
                        }
                    })
                    
                }).catch(()=>{
                    that.$message({
                        type: 'info',
                        message:'已取消删除'
                    })
                })
            },
            //添加子分类
            addsubclass(row){
                console.log(row)
                let that = this;
                const super_id = row.id;
                // const super_id = row.super_id;
                that.$prompt('分类名称','添加分类',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value })=>{
                     let formData = new FormData();
                    //  formData.append('id',id);
                     formData.append('name',value);
                     formData.append('super_id',super_id);
                    that.$http.axiosPost(that.$api.classification,formData,(res)=>{
                        console.log(199,res);
                        that.getClasList();
                    });
                    that.$message({
                        type: 'success',
                        message:'添加'+ value + '成功'
                    })
                }).catch(()=>{
                    that.$message({
                        type:'info',
                        message:'取消添加'
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding: 12px 9px;
    }
    .demo-table-expand{
        border-bottom: 1px solid #F3F5F7;
        height: 57px!important;
        padding-left: 260px;
        line-height: 57px;
        position: relative;
        margin: 0!important;
    }
    .el-table__expanded-cell .demo-table-expand:last-child{
        border-bottom: none
    }
    .ejbtn1{
        position: absolute;
        right: 250px;
        top: 8px;
    }
    .ejbtn2{
        position:absolute;
        right: 210px;
        top: 8px;
    }
</style>